.App {
  text-align: left;
  
}

.content{
 
  color: #FF9900;
    font-size: 20px;
    font-family:"InterVariable", "Inter var", "Inter", -apple-system, BlinkMacSystemFont,
          "Helvetica Neue", "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans",
          sans-serif;
    text-decoration: none; /*takes off the 'default' underline*/
    

  margin:.5em;
  font-weight: bold;
 
  
}

.contentTime{
 
  color: #01ff6f;
    font-size: 20px;
    font-family:"InterVariable", "Inter var", "Inter", -apple-system, BlinkMacSystemFont,
          "Helvetica Neue", "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans",
          sans-serif;
    text-decoration: none; /*takes off the 'default' underline*/
    

 
  font-weight: bold;
 
 
}

.content:hover{

  color: #ffffff;
}



:root, [data-amplify-theme] {
  font-family: var(--amplify-fonts-default-static);
}

.amplify-flex { /* set for the middle div of the amplify login */
  background-color:'transparent';

}

.amplify-field-group__outer-end { /* the eye button is the outer-end */
  display: flex;
  align-items: var(--amplify-components-fieldgroup-outer-align-items);

  background: linear-gradient(
    to right,
    var(--amplify-colors-blue-10),
    var(--amplify-colors-blue-80)
  );
}

.amplify-input:hover{

  border-color: #FF9900;
  box-shadow: var(--amplify-components-fieldcontrol-focus-box-shadow);
  border-width: thin;

  


}

.amplify-input{

  background: linear-gradient(
    to left,
    var(--amplify-colors-blue-10),
    var(--amplify-colors-blue-80)
  );

  color: #ffffff;

  
  font-size: 16px;
  font-weight: bold;
}


.amplify-input:focus {
  border-color: #FF9900;
  box-shadow: var(--amplify-components-fieldcontrol-focus-box-shadow);
  border-width: thin;
 
}

.amplify-input:focus:hover{
  border-color: #cb1313;
  box-shadow: var(--amplify-components-fieldcontrol-focus-box-shadow);
  border-width: thin;
 
}



.amplify-tabs-item:hover {
  color:#FF9900;
  cursor: pointer;
}

.amplify-tabs-item[data-state=active] {
  color: #ffffff;
  border-color:#FF9900;
  background-color:#FF9900;;
  transition-property: none;
}


.amplify-button[data-variation='primary'] {
  
  background: linear-gradient(
    to right,
    var(--amplify-colors-blue-10),
    var(--amplify-colors-blue-80)
  );
  color: #000000; /* black text on sign in */
  font-size: 1.5rem;
  padding: 1rem 2rem;
  border: 2px solid rgb(255, 255, 255);
  border-color: var(--amplify-colors-blue-80);
}

.amplify-button:hover {  /*applies to any amplify button*/
  background-color: black;
  border-color: #ffffff;;
  color: #ffffff;
  
}

.amplify-button--small { /*small button for forgot password*/

  
  background-color: black;
  color: #ffffff;
 
}

.amplify-button--small:hover{ /*small button for forgot password*/

  
  background-color: rgb(165, 9, 9);
  color: #ffffff;
 
}

body {
  margin: 0;
  /*
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(./pages/dots.jpeg);
  background-size: 100% auto;
  /*color: #fd0000;*/ /* the color of the text intput */
}

.z{
  color: #e2e1e8;
  font-family:"InterVariable", "Inter var", "Inter", -apple-system, BlinkMacSystemFont,
  "Helvetica Neue", "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans",
  sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 1px;
  
  margin-left: 20px; /* adjust to time to be left side in line with top links HOME ABOUT LOGIN */
}

.z:hover{

  font-size: 16px;
  color: #FF9900;
}

.timeColor{

  color: #02c00f;
  font-family:"InterVariable", "Inter var", "Inter", -apple-system, BlinkMacSystemFont,
  "Helvetica Neue", "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans",
  sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 1px;
  margin-left: 20px; /* adjust to time to be left side in line with top links HOME ABOUT LOGIN */
  text-decoration: none; /*takes off the 'default' underline*/
 

}

.timeColor:hover{

  color: #ffffff;
}



.authButton{

  color: #FF9900;
    font-size: 20px;
    font-family: "Lucida Console", "Courier New", monospace;
    margin-left: 40px;
    background-color: transparent;
    margin-left: 20px; /* adjust to time to be left side in line with top links HOME ABOUT LOGIN */
    border-color: #ffffff;
   border-width: thick;

}

h2 {
  color: #b32253;
  font-family: arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 1px;
  }

::placeholder{

color: #ffffff; /* the color of the text intput */

}



textarea {
  /* this was making the cursor start not in the top left but close inside  padding: 3%;*/
   border-color: #807f7f;
   border-width: thick;
   background-color: #121312e1;
   color: #ffffff;
   font-size: 16px;
   font-family: "Lucida Console", "Courier New", monospace;
   margin-left: 20px; /* adjust to time to be left side in line with top links HOME ABOUT LOGIN */
}